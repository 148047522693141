import * as React from 'react';

import Layout from '../components/layouts/core';

import colors from '../colors/colors';

import HorizontalLogo from '../components/HorizontalLogo';
import StoryWrapper from '../components/StoryWrapper';
import BannerBackground from '../components/BannerBackground';

const pageStyles = {
  margin: 0,
  padding: 0,
  color: '#232129',
};

const bannerStyles = {
  display: 'flex',
  justifyContent: 'center',
  aignItems: 'center',
  backgroundColor: colors.red,
};

const storyStyles = {
  color: colors.green,
  width: '100%',
  maxWidth: '30rem',
};

const storyHeaderStyles = {
  ...storyStyles,
  margin: 0,
  fontSize: 'calc(2rem + 5vw)',
  maxWidth: '20rem',
};

const flexWrapperStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1rem',
};

const flexWrapperChild = {
  ...flexWrapperStyles,
  width: '50%',
  maxWidth: '30rem',
};

const IndexPage = () => (
  <Layout>
    <main style={pageStyles}>
      <title>Home Page</title>
      <div style={bannerStyles}>
        <BannerBackground>
          <div style={{ padding: '5vw' }}>
            <HorizontalLogo />
          </div>
        </BannerBackground>
      </div>
      <StoryWrapper />
    </main>
  </Layout>
);

export default IndexPage;
