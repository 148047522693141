import * as React from 'react';

import colors from '../colors/colors';

const storyWrapperStyles = {
  padding: '10vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  background: `linear-gradient(${colors.nightSky}, ${colors.nightSkyLight})`,
};

const headingStyles = {
  width: '100%',
  maxWidth: '25rem',
  textAlign: 'center',
  margin: '0 0 3rem',
  padding: '0 0 1rem',
  borderBottom: `2px solid white`,
  color: 'white',
};

const paragraphStyles = {
  width: '100%',
  maxWidth: '25rem',
  margin: '0 0 1.5rem',
  color: colors.blue,
  fontSize: '1.5rem',
  lineHeight: '2rem',
};

export default () => (
  <div style={storyWrapperStyles}>
    <h1 style={headingStyles} className='press-start'>
      Pickle Wars
    </h1>
    <p style={paragraphStyles} className='press-start'>
      Pickle Wars is an NFT project created by Dandy Lines focusing on the conflict between Pickle Haters United (PHU),
      and The Pickle Republic (TPR).
    </p>
    <p style={paragraphStyles} className='press-start'>
      We create unique characters and imagery to capture the absurdity of a world where pickles fight to survive
      eradication.
    </p>
  </div>
);
