import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const HorizontalLogo = () => (
  <div style={{ maxWidth: '800px', margin: '0 auto' }}>
    <StaticImage
      src='../images/pickle-wars-logo-landscape.png'
      alt='Pickle Wars Logo'
      loading='eager'
      placeholder='tracedSVG'
    />
  </div>
);

export default HorizontalLogo;
