import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

const bgImageStyles = {
  width: '100%',
  backgroundAttachment: 'fixed',
  backgroundPosition: 'center',
  backgroundRepeat: 'repeat',
  backgroundSize: '200px',
};

const BannerBackground = ({ className, children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "tiles-bg.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  const imageData = data.desktop.childImageSharp.fluid;

  return (
    <BackgroundImage Tag='section' className={className} fluid={imageData} style={bgImageStyles}>
      {children}
    </BackgroundImage>
  );
};

export default BannerBackground;
