const colors = {
  green: '#8DBB45',
  brightGreen: '#C0EB71',
  blue: '#6B8CDB',
  red: '#DB5C40',
  rust: '#8F4131',
  pickle: '#4C6524',
  outline: '#2E3E15',
  nightSky: '#081120',
  nightSkyLight: '#102342',
}

export default colors;