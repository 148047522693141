import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const StackedLogo = () => (
  <div style={{ maxWidth: '1000px', margin: '0 auto' }}>
    <StaticImage src='../images/pickle-wars-logo-stacked.png' alt='Pickle Wars Logo' placeholder='tracedSVG' />
  </div>
);

export default StackedLogo;
