import * as React from 'react';

import colors from '../../colors/colors';

import FlexWrapper from '../display/FlexWrapper';
import ThreeColumns from '../display/ThreeColumns';

import StackedLogo from '../StackedLogo';

import Discord from '../icons/Discord';
import Medium from '../icons/Medium';
import Twitter from '../icons/Twitter';

const footerStyles = {
  backgroundColor: colors.outline,
};

const columnStyles = {
  padding: '2rem 1rem',
  order: 1,
};

const fontColor = {
  color: colors.brightGreen,
};

const headerStyles = {
  ...fontColor,
  margin: '0 0 1rem',
  fontSize: '1.1rem',
};

const linkStyles = {
  ...fontColor,
  display: 'block',
  textDecorationColor: colors.pickle,
  fontSize: '0.85rem',
  margin: '0.75rem 0',
  height: '1.25rem',
};

export default () => (
  <footer style={footerStyles}>
    <ThreeColumns align='stretch'>
      <FlexWrapper align='center' justify='center' style={columnStyles}>
        <div style={{ width: '60%' }}>
          <StackedLogo />
        </div>
      </FlexWrapper>
      <div style={columnStyles}>
        <h3 style={headerStyles}>Pickle Wars</h3>
        <a style={linkStyles} href='https://twitter.com/PickleWarsNFT'>
          <FlexWrapper align='center' justify='start'>
            <Twitter aspect='1.25rem' />
            <span style={{ marginLeft: '0.5rem' }}>Twitter - @PickleWarsNFT</span>
          </FlexWrapper>
        </a>
        <a style={linkStyles} href='https://discord.gg/DtVcC9XZ'>
          <FlexWrapper align='center' justify='start'>
            <Discord aspect='1.25rem' />
            <span style={{ marginLeft: '0.5rem' }}>Discord</span>
          </FlexWrapper>
        </a>
        <a style={linkStyles} href='https://picklewars.medium.com/'>
          <FlexWrapper align='center' justify='start'>
            <Medium aspect='1.25rem' />
            <span style={{ marginLeft: '0.5rem' }}>Medium</span>
          </FlexWrapper>
        </a>
      </div>
      <div style={columnStyles}>
        <h3 style={headerStyles}>Dandy Lines</h3>
        <a style={linkStyles} href='https://twitter.com/dandylinesNFT'>
          <FlexWrapper align='center' justify='start'>
            <Twitter aspect='1.25rem' />
            <span style={{ marginLeft: '0.5rem' }}>Twitter - @DandyLinesNFT</span>
          </FlexWrapper>
        </a>
        <a style={linkStyles} href='https://dandylines.medium.com/'>
          <FlexWrapper align='center' justify='start'>
            <Medium aspect='1.25rem' />
            <span style={{ marginLeft: '0.5rem' }}>Medium</span>
          </FlexWrapper>
        </a>
      </div>
    </ThreeColumns>
  </footer>
);
