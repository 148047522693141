import React from 'react';

import Footer from './footer';

import './core.css';

export default ({ children }) => (
  <React.Fragment>
    {children}
    <Footer />
  </React.Fragment>
);
